.content .apresentation {
  text-align: center;
}
.error > p {
  color: var(--red);
}
.hidden {
  display: none;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  column-gap: 20px;
}
.col {
  grid-column: 1 / 6;
}
.col_1 {
  grid-column: span 1;
}
.col_1_column {
  grid-column: span 1;
  display: flex;
  flex-direction: column;
}
.col_6 {
  grid-column: span 5;
}

.modal_loading {
  width: 100%;
  height: 100vh;
  filter: opacity(100%);
  background-color: rgba(0, 0, 0, 0.171);
  display: flex;
  position: fixed;
  border-radius: 10px;
  top: 0;
  left: 0;
}
.show_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.show_loading > strong {
  color: #f05923;
}
.show_alert_cpf {
  display: block;
}
.show_alert_cpf strong {
  color: var(--red);
}

.content .form_register {
  margin-top: 15px;
}
.content .form_register input {
  width: 100%;
  margin-bottom: 10px;
  font-size: 1rem;
}

.content .form_register .half_section {
  display: flex;
  flex-direction: column;
}
.content button {
  margin-top: 10px;
  width: 100%;
}
.content .link {
  margin-top: 10px;
  color: var(--btnSucceess);
}
.content .link:hover {
  color: var(--green-Dark);
}

.select {
  position: relative;
  display: inline;
}
.select:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}
.select:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 0.3em;
  right: 0.75em;
  border-top: 8px solid black;
  opacity: 0.5;
}

@media (min-width: 600px) {
  .modal_loading {
    width: 100%;
  }
  .content .form_register {
    width: 450px;
  }
  .content button {
    width: 450px;
  }
}
