.btn {
  cursor: pointer;
  background-color: var(--btnSucceess);
  border: 0;
  border-radius: 10px;
  color: white;
  padding: 10px;
  transition: 0.2s;
  font-size: 1rem;
}
.btn:focus {
  outline: 0;
}
.btn:hover {
  background-color: var(--green-Dark);
  color: white;
}
