.show_alert_msg p {
  color: var(--red);
}
.content textarea {
  padding: 10px;
  resize: none;
}
.content .input_lts {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.content span {
  margin-top: 20px;
}
.content .input_lts label {
  font-size: 0.9rem;
}
.content .info_endereco {
  margin-top: 20px;
}
.content .info_endereco :not(p:first-child) {
  font-size: 1.1rem;
}
.content .info_endereco p:first-child {
  text-decoration: underline;
  font-weight: 500;
  font-size: 1.2rem;
}
.content .section_button {
  display: flex;
  flex-direction: column;
  margin-top: calc(150px - 40px);
}
@media (min-width: 600px) {
  .content .input_lts input {
    width: 300px;
  }
  .content .section_button {
    width: 300px;
  }
}
