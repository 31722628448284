.hide {
  top: 5rem;
  transition: 0.5s;
  left: -300px;
  position: absolute;
}
.header {
  width: 100%;
  background-color: var(--home);
  height: 5rem;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  position: fixed;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.678);
}
.header span.left_side i {
  color: black;
  font-size: 30px;
}
.header span.right_side img {
  width: 80px;
}
.menuHamburguer {
  border: 1px solid rgba(0, 0, 0, 0.452);
  position: fixed;
  width: 300px;
  height: calc(5rem * 1rem);
  background-color: var(--home);
  left: 0;
  top: 5rem;
  transition: 0.5s;
  z-index: 999;
}
.options_menu > li {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: left;
  border-left: 5px solid #f05923;
}
.options_menu > li:not(:last-child) {
  border-bottom: 1px solid black;
}
.options_menu > li > a {
  display: block;
}
.label_menu:active i {
  transform: rotate(90deg);
}
@media (max-width: 1024px) {
  .options_menu .extra_menu {
    display: none;
  }
}
