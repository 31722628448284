nav.bottom_menu_navigation {
  height: 3rem;
  background-color: var(--home);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  box-shadow: 1px -1px 6px 0px rgb(0 0 0 / 68%);
}
nav.bottom_menu_navigation .list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
.row_1 {
  grid-column-start: 2;
  grid-column-end: 4;
}
.row_2 {
  grid-column: span 2;
}
nav.bottom_menu_navigation .list li a {
  display: block;
}
nav.bottom_menu_navigation .list li a > i {
  font-size: 1.7rem;
}
@media (min-width: 1000px) {
  nav.bottom_menu_navigation {
    display: none;
  }
}
