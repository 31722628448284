.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.hidden {
  display: none;
}

.show_alert_msg p {
  color: var(--red);
}
.content section.formLogin {
  margin: 20px 0;
  width: 100%;
}
.content section.formLogin form {
  display: flex;
  flex-direction: column;
}
.content section.formLogin label {
  margin-top: 20px;
}
.content section.formLogin button {
  margin-top: 20px;
}
.content .link {
  margin-top: 10px;
  color: var(--btnSucceess);
}
.content .link:hover {
  color: var(--green-Dark);
}
@media (min-width: 600px) {
  .content section.formLogin {
    width: 450px;
  }
}
