:root {
  --background: white;
  --text: #333;
  --btnSucceess: #0b813c;
  --home: #dbddd0;
  --content: #eeeeee;
  /* --home: #dbddd0; */
  --green-Dark: #003e1f;
  --red: rgb(184, 34, 34);
  --red-dark: rgb(95, 25, 25);
  --green-light: #4caf50;
  --table-color: #e8c035;
  /*  */
  --azul_50: rgb(102, 128, 168);
  --azul_100: #335b99;
  --azul_300: #264473;
  --pink_50: #f5e9e3;
  --pink_100: #f4d7c9;
  --amarelo_100: #fcd100;
  --amarelo_300: #ef9f00;
  --laranja_500: #f05923;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  color: var(--text);
}
select.select {
  padding: 10px;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid black;
  background: var(--background)
    url('https://img.flaticon.com/icons/png/512/60/60995.png?size=1200x630f&pad=10,10,10,10&ext=png&bg=FFFFFFFF')
    no-repeat center right;
  background-size: 20px;
}
/*  */
select.select::-ms-expand {
  display: none;
}
/*  */
input.input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
}
/* Link */
a.link {
  text-decoration: none;
  color: var(--text);
}
a.link:hover {
  color: var(--text);
}
/* Lista */
ul.list_ul {
  list-style-type: none;
}
/* Table */
table.table {
  border-collapse: collapse;
  border: 1px solid #ccc;
}
table.table th {
  background-color: var(--table-color);
  color: white;
  padding: 5px;
}
.table tr {
  text-align: center;
}
.table tr:nth-child(even) {
  background: #fff;
}
.table tr:nth-child(odd) {
  background: #f2f2f2;
}
table.table,
th,
td {
  border: 1px solid #ccc;
}
@media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
  select.select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: var(--background);
  }
}
