.content {
  display: flex;
  flex-direction: column;
}
.content .table_coleta {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.content .section_button {
  display: flex;
  flex-direction: column;
}
.content .section_button button {
  margin-top: 20px;
}
@media (min-width: 600px) {
  .content .section_button {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .content .section_button button {
    width: 450px;
  }
}
