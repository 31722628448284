.main_nologged {
  /* padding: 20px; */
  width: 100%;
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  background-color: var(--content);
}
.main_logged {
  padding: 10px 0;
  width: 100%;
  margin-top: 5rem;
  height: calc(100vh - 8rem);
  margin: 5rem 0 3rem 0;
  background-color: var(--content);
}
@media (min-width: 1000px) {
  .main_logged {
    height: calc(100vh - 5rem);
    margin: 5rem 0 0 0;
  }
}
