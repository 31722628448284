.formUpdateUser {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formUpdateUser .sectionInput {
  display: flex;
  flex-direction: column;
  width: 250px;
}
.formUpdateUser .sectionButtons button {
  margin: 10px;
  width: 100px;
}
.formUpdateUser .sectionInput label {
  font-size: 0.9rem;
  margin-top: 10px;
}
.formUpdateUser .sectionButtons button:last-child {
  background-color: var(--red);
}
@media (min-width: 600px) {
  .formUpdateUser .sectionInput {
    width: 450px;
  }
  .formUpdateUser .sectionInput {
    display: flex;
    flex-direction: column;
  }
  .formUpdateUser .sectionButtons button {
    width: 225px;
  }
}
