.main {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.row {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  gap: 15px;
}
.col {
  grid-column: span 1;
}
.col_2 {
  grid-column: span 2;
}
.col_3 {
  grid-column: span 3;
}
.col_4 {
  grid-column: span 4;
}
.check {
  text-align: left;
}
.main_content .card {
  padding: 10px;
  width: 200px;
  height: 250px;
  background-color: var(--azul_50);
  border-radius: 10px;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.247);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main_content .card .card_descripton {
  text-align: center;
}
.main_content .card .card_descripton p {
  color: white;
  font-size: 0.7rem;
}
.main_content .card .card_descripton label {
  margin-right: 5px;
  color: white;
}
.content .section_button {
  display: flex;
  flex-direction: column;
  margin-top: calc(150px - 20px);
}
@media (min-width: 600px) {
  .content .section_button {
    align-items: center;
  }
  .content .section_button button {
    width: 450px;
  }
}
@media (max-width: 600px) {
  .row {
    grid-template-columns: repeat(1, 1fr);
  }
  .main_content .card {
    width: auto;
    margin-right: 10px;
  }
}
